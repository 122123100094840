import React, { Component } from "react";
import { ReactComponent as House } from "../../assets/images/casa.svg";
import Bar from "../../assets/images/barra-amior.png";

import "./loader.scss";


class Loader extends Component {

  componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="load-container">
        <img src={Bar} className="bar" />
        <House className="house" />
      </div>
    )
  }
}

export default Loader;