import React, { Component, Suspense, lazy } from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Loader from "./components/Loder/loader";
import CookieConsent from "./components/CookieConsent/CookieConsent";
const Home = lazy(() => import('./pages/Home/Home'));
const Navbar = lazy(() => import("./components/Nav/Navbar"));
const SideIcons = lazy(() => import("./components/SideIcons/SideIcons"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const Gastronomy = lazy(() => import("./pages/Gastronomy/Gastronomy"));
const Menu = lazy(() => import("./pages/MenuImages/MenuImages"));
const ExperiencesPage = lazy(() => import("./pages/ExperiencesPage/ExperiencesPage"));
const TheHotel = lazy(() => import("./pages/TheHotel/TheHotel"));
const Wedding = lazy(() => import("./pages/Wedding/Wedding"));
const Acommodations = lazy(() => import("./pages/Acommodations/Acommodations"));
const Acommodation = lazy(() => import("./pages/Acommodation/Acommodation"));
const ContactFront = lazy(() => import("./components/ContactFront/ContactFront"));
const ReservationBar = lazy(() => import("./components/ReservationBar/ReservationBar"));
const Reservations = lazy(() => import("./pages/Reservations/Reservations"));
const Omnibees = lazy(() => import("./pages/Omnibees/Omnibees"));
const NotAMatch = lazy(() => import("./pages/404/404"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));

const BasicContainer = (context) => {
  return () => (
    <Switch>
      <Suspense fallback={<Loader />}>
        <Route exact path="/reservas/omnibees"
          render={props => (
            <Omnibees
              {...props}
              language={context.state.language}
            />
          )}
        />
        <Route
          exact path="/reservas"
          render={props => (
            <Reservations
              {...props}
              language={context.state.language}
            />
          )}
          history={createBrowserHistory}
        />
      </Suspense>
    </Switch>
  )
};

const DefaultContainer = context => {
  return () => (
    <>
      <Suspense fallback={<p></p>}>
        <Navbar
          reservationToggle={context.reservationToggle}
          showContact={context.animationContact}
          language={context.state.language}
          changeLanguage={context.changeLanguage}
          history={createBrowserHistory}
        />
        <SideIcons />
        <CookieConsent language={context.state.language} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            path="/"
            exact
            render={props =>
              <Home {...props} language={context.state.language}
                showContact={context.animationContact} />}
            history={createBrowserHistory}
          />
          <Route
            path="/pousada"
            render={props => (
              <TheHotel {...props} language={context.state.language} />)}
            history={createBrowserHistory}
          />
          <Route
            path="/acomodacoes/:id"
            render={props => (
              <Acommodation
                {...props}
                key={window.location.href}
                language={context.state.language} />)}
            history={createBrowserHistory}
          />
          <Route
            path="/acomodacoes"
            render={props => (
              <Acommodations
                {...props} language={context.state.language} />)}
            history={createBrowserHistory}
          />
          <Route
            path="/gastronomia"
            render={props => (
              <Gastronomy
                {...props}
                language={context.state.language}
                showContact={context.animationContact} />)}
            history={createBrowserHistory}
          />
          <Route
            path="/experiencias"
            render={props => (
              <ExperiencesPage {...props} language={context.state.language} />)}
            history={createBrowserHistory}
          />
          <Route
            path="/casamentos"
            render={props => (
              <Wedding
                {...props}
                language={context.state.language}
                showContact={context.animationContact} />)}
            history={createBrowserHistory}
          />
          <Route
            path="/cardapio"
            render={props => (
              <Menu
                {...props}
                language={context.state.language}
                showContact={context.animationContact} />)}
            history={createBrowserHistory}
          />
          <Route
            path="/politica-de-privacidade"
            render={props =>
              <PrivacyPolicy {...props} language={context.state.language} showContact={context.animationContact}/>}
            history={createBrowserHistory}
          />
          <Route
            render={props => (
              <NotAMatch
                {...props}
                reservationToggle={context.reservationToggle}
                language={context.state.language} />)}
            history={createBrowserHistory}
          />

        </Switch>
      </Suspense>
      <Suspense fallback={<p></p>}>
        <ContactFront language={context.state.language} />
        <Footer
          showContact={context.animationContact}
          language={context.state.language}
        />
        <ReservationBar
          language={context.state.language}
          reservationToggle={context.reservationToggle}
        />
      </Suspense>
    </>
  )
};

class App extends Component {
  state = {
    response: "",
    isReservationOpen: false,
    language: "pt"
  };

  componentDidMount = async () => {
    this.callApi()
      .then(res => this.setState({ response: res.express }))
      .catch(err => console.log(err));
  }

  showCoconutTrees(e) {
    let url = window.location.href;
    let section = url.substring(url.lastIndexOf("/") + 1);
    if (section !== "pousada") {
      let element = document.querySelector(".left-coconut-trees");
      element.style.display = "none";

      document.querySelector(".right-coconut-trees").style.display = "none";
    } else {
      let element2 = document.querySelector(".left-coconut-trees");
      element2.style.display = "flex";

      document.querySelector(".right-coconut-trees").style.display = "flex";
    }
  }

  callApi = async () => {
    const response = await fetch("/");
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    return body;
  };

  animationContact(e) {
    const element = document.querySelector(".contact-background");
    const window = document.querySelector(".contact-window");
    element.style.display = "flex";
    element.classList.remove("animated", "slideOutUp");
    element.classList.add("animated", "slideInDown");
    element.style.backgroundColor = "rgba(0, 0, 0, 0.27)";
  }

  changeLanguage = language => {
    this.setState({
      ...this.state,
      language: language
    });
    let caminho = this.props;
  };

  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route
              path="/reservas"
              component={BasicContainer(this)}
            />
            <Route component={DefaultContainer(this)} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
