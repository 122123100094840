import React, { Component } from "react";
import Cookies from "universal-cookie";
import "./CookieConsent.scss";

const cookies = new Cookies();

const contentText = {
    "pt": "Utilizamos cookies para te oferecer uma melhor experiência. Saiba mais acessando a nossa ",
    "en": "We use cookies to give you a better experience. Find out more by accessing our ",
    "es": "Utilizamos cookies para ofrecerle una mejor experiencia. Obtenga más información accediendo a nuestra ",
}

const privacyPolicyText = {
    "pt": " política de privacidade.",
    "en": " politica de privacidad.",
    "es": " privacy policy.",
}

export default class CookieConsent extends Component {
    constructor(props) {
        super(props);
        this.block = React.createRef();
        this.state = {
            content: [],
            active: false
        };

        this.closeCookieConsent = this.closeCookieConsent.bind(this);
    }

    componentWillMount() {
        const cookieConsent = cookies.get("cookieConsent");

        if (!cookieConsent) {
            this.setState({ active: true })
        }
    }

    closeCookieConsent() {
        cookies.set("cookieConsent", "true", { path: "/", maxAge: 3600 });

        this.setState({ active: false })
    }

    render() {
        const { language } = this.props;

        return (
            <>
                {this.state.active ?
                    <div
                        className="cookie-consent-container"
                    >
                        <p><span>{contentText[`${language}`]}</span><a href="/politica-de-privacidade">{privacyPolicyText[`${language}`]}</a></p>
                        <p className="close-btn" onClick={this.closeCookieConsent}>COMPREENDO</p>
                    </div> : ""}
            </>
        );
    }
}
